import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionState, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { SignalRMethod } from '../enums/signal-r-method.enum';
import { UserService } from '../../user';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: HubConnection

  constructor(private userService: UserService) {
  }

  public notification = new Subject<{ 
    method: SignalRMethod, 
    sessionId: string
    res: any 
  }>();
  private notification$ = this.notification.asObservable();


  public connectOnHub(url: string): void {
    if (
      !this.isHubConnected(this.hubConnection)
    ) {
      this.startConnectionOnHub(url);
    }
  }

  public disconnectFromHub(): void {
    if(this.isHubConnected(this.hubConnection)) this.hubConnection.stop();
  }

  public isHubConnected(hubConnection: signalR.HubConnection): boolean {
    const connectionStates = [
      HubConnectionState.Connected,
      HubConnectionState.Connecting,
    ];

    return !!hubConnection && connectionStates.includes(hubConnection.state);
  }

  public onHubNotification(): Observable<any> {
    return this.notification$;
  }

  private startConnectionOnHub(url: string): void {
    this.hubConnection = this.createConnectionOnHub(url);
    this.hubConnection
      .start()
      .then(() => {
        this.invokeMethodOnHub(SignalRMethod.AddToGroupAsync, this.userService.getUser().id);
        this.onSessionNotification()
      })
      .catch((error) => {
        console.error('SignalR error', error);
        setTimeout(() => this.startConnectionOnHub(url), 5000);
      });
  }

  private invokeMethodOnHub(method: string, group: string): void {
    this.hubConnection.invoke(method, group).catch((err) => {
      console.error(err.toString());
    });
  }

  private createConnectionOnHub(url: string): signalR.HubConnection {
    return new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();
  }

  private onSessionNotification(): void {
    this.hubConnection.on(
      SignalRMethod.ReceiveSessionNotification, 
      (sessionId: string, res: any) => {
        this.notification.next({
          method: SignalRMethod.ReceiveSessionNotification,
          sessionId,
          res
        });
      }
    );
  }
}
