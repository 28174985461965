import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INTERCEPT_ALTERNATIVE_PREFIX, INTERCEPT_CREDICORP_AUTH } from '../consts/consts';

@Injectable({
  providedIn: 'root',
})
export class ExternalHttpService extends HttpClient {

  public alternateUrl: string;

  constructor(private httpHandler: HttpHandler) {
    super(httpHandler);
  }

  request(method?: any, url?: any, options?: any): any {
    return super.request(method, url, {
      ...options,
      headers: new HttpHeaders({
        //TODO: Remover isso no futuro.
        [INTERCEPT_CREDICORP_AUTH]: 'true',
        [INTERCEPT_ALTERNATIVE_PREFIX] : this.alternateUrl,
        ...options?.headers,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
    });
  }

}
