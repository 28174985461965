import { Inject, Injectable } from '@angular/core';
import { HttpService } from '../_http-settings/services';
import { Observable } from 'rxjs';
import { CustomProject, Project, Projects, } from './batch.model';
import { limit, statsResponse } from './dash.model';

@Injectable({
  providedIn: 'root',
})
export class BatchService {
  //TODO: Trocar todas as nomeclaturas any por respostas da API.
  constructor(
    private http: HttpService,
    @Inject('appStorage') private appStorage: string
  ) {}

  public get(page: number, perPage: number, search?: string): Observable<Projects> {
    if(search) return this.http.get<Projects>(`/batch/file?search=${search}&limit=${perPage}&page=${page}`);
    return this.http.get<Projects>(`/batch/file?limit=${perPage}&page=${page}`, {
      headers: { 'Access-Control-Allow-Origin': '*' },
    });
  }

  public uploadCustomProject(file: CustomProject) : Observable<any> {
    Object.keys(file.filter).forEach(element => {
      const currentValue = file.filter[element]
      if(currentValue != null && 'from' in currentValue){
        if(currentValue.from == null && currentValue.to === null) file.filter[element] = null
      }
    }); 

    return this.http.post('/batch/custom', { ...file })
  }

  public upload(file: any, fileName: string): Observable<Project> {
    const formData = new FormData();
    formData.set('file', file);
    formData.set('name', fileName);
    return this.http.post<Project>(`/batch/file`, formData);
  }

  public analyze(id: string): Observable<any> {
    return this.http.post<any>(`/batch/stats/${id}`, null, {headers: { 'Content-Type': 'text/plain; charset=utf-8' }, responseType: 'text' as 'json' });
  }

  public delete(id: string): Observable<any> {
    return this.http.delete<any>(`/batch/file/${id}`);
  }

  public deleteExtraction(id: string): Observable<any> {
    return this.http.delete<any>(`/batch/extraction/${id}`);
  }
  public loadLimit() {
    return this.http.get<limit>('/users/limit');
  }

  public mount(session: string): Observable<statsResponse> {
    return this.http
      .get<statsResponse>(`/batch/stats/${session}`)
  }

  public databaseGeneration(id: string, LIMITE: number) {
    return this.http.patch(
      `/batch/start/${id}`,
      { LIMITE },
      { responseType: 'text' }
    );
  }

  public getExtractions(page: number, perPage: number, search?: string) : Observable<any> {
    if(search) return this.http.get<Projects>(`/batch/extraction?search=${search}&limit=${perPage}&page=${page}`);
    return this.http.get<Projects>(`/batch/extraction?limit=${perPage}&page=${page}`);
  }

  public downloadFile(id: string) {
    return this.http.get(`/batch/extraction/download/${id}`, {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
