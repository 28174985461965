import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SalesCnae } from '../model/salescore.model';
import { ExternalHttpService } from '..';
import { mergeMap, tap } from 'rxjs/operators';
import { MaskService } from 'ngx-mask';
import { HttpErrorResponse } from '@angular/common/http';
import { AppStoragePrefixToken } from '../consts/tokens';

@Injectable({
  providedIn: 'root',
})
export class SalesCoreService {
  constructor(@Inject(AppStoragePrefixToken) private storagePrefix: string, private http: ExternalHttpService, private maskService: MaskService) {
    this.http.alternateUrl = 'salescore';
  }

  public getCnaes(params): Observable<SalesCnae[]> {
    return this.http.get<SalesCnae[]>('/cnae/custom/project/all', { params }).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.clearTokens();
              window.location.reload();
            }
          }
        }
      )
    );
  }

  getCnaesFormattedToSelect(cnae?: string): Observable<SalesCnae[]> {

    const code = cnae ? cnae : '';

    let allCnaes: SalesCnae[] = [];

    const params = {
      code,
    };

    const getAllPages = (): Observable<SalesCnae[]> => {
      return this.getCnaes(params).pipe(
        mergeMap((response: SalesCnae[]) => {
          const cnaes = (response as unknown as SalesCnae[]).map(
            (cnae: SalesCnae) => ({
              code: cnae.code,
              description: `${this.maskService.applyMask(cnae.code, '0000-0/00')}: ${cnae.name}`,
            })
          );

          allCnaes = allCnaes.concat(cnaes);

          return of(allCnaes);
        })
      );
    };

    return getAllPages();
  }

  private clearTokens() {
    localStorage.removeItem(`${this.storagePrefix}.credicorpToken`);
    return localStorage.removeItem(`${this.storagePrefix}.token`);
  }
}
