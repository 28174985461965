export enum Category {
    ICP,
    Custom,
}

export const getCategoryRoute = {
    [Category.ICP]: '/results/icp',
    [Category.Custom]: '/results/custom',
};

export const getCategoryByValue = {
    ['ICP']: Category.ICP,
    ['CUSTOM']: Category.Custom
}
